import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"color": "--dark",
	"background": "--color-light"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h2",
			"font": "--headline1",
			"margin": "0 0 8px 0",
			"children": "FAQs"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "0",
			"color": "--greyD2",
			"children": "Need answers? We got’em"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "66.66%",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "How hard is it to create a strategy?"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "It's easy. After studying our video lessons, in 2-3 days you will be able to create and test your own strategies. The principle of strategy creation is as comfortable as possible for a regular TradingView user."
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "What assets can I test strategies on?"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "On any asset that is available on the TradingView platform. And on any timeframe."
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Can I automate the trading strategies?"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Yes. You can customize the sending of trade alerts to your exchange using Webhooks. Or use our telegram bot to send messages to you or to your channel."
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "I have my own indicators. Can I use them?"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Yes. Most indicators can be used with CDZV at once. Some require 2-3 lines of code to be added to them. But don't worry - we will make these changes for you for free."
		}
	}
};

const CdzvFaq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<Text {...override("text1")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Box {...override("box5")}>
						<Box {...override("box6")}>
							<Text {...override("text2")} />
							<Text {...override("text3")} />
						</Box>
					</Box>
					<Box {...override("box7")}>
						<Box {...override("box8")}>
							<Text {...override("text4")} />
						</Box>
						<Text {...override("text5")} />
					</Box>
					<Box {...override("box9")}>
						<Box {...override("box10")}>
							<Text {...override("text6")} />
							<Text {...override("text7")} />
						</Box>
					</Box>
					<Box {...override("box11")}>
						<Box {...override("box12")}>
							<Text {...override("text8")} />
							<Text {...override("text9")} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvFaq, { ...Section,
	defaultProps,
	overrides
});
export default CdzvFaq;