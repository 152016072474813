import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>Code Zero Visual Trading | Build, Test and Automate trading strategies without programming.</title>
			<meta name="keywords"
				  content="Code Zero Visual Trading, CDZV, TradingView, indicator, strategy, backtesting, algo-trading, trading, forex, stock, crypto, cryptocurrency, profit, algorithmic trading, automated trading, trading tools, trading platform, no-code trading, visual trading, trading automation, create strategy, build strategy, test strategy, automate strategy, webhooks"/>
			<meta name="description"
				  content="Code Zero Visual Trading (CDZV) fot TradingVieew. Build, test and automate your trading strategy in minutes without programming. Quickly evaluate your ideas and turn them into profitable solutions with our tools. Build strategies in minutes without coding skills."/>
			<meta property={"og:description"} content={"Code Zero Visual Trading (CDZV) fot TradingVieew. Build, test and automate your trading strategy in minutes without programming. Quickly evaluate your ideas and turn them into profitable solutions with our tools. Build strategies in minutes without coding skills."} />
			<meta name="author" content="Code Zero Visual Trading"/>
			<link rel="canonical" href="https://cdzv.com/"/>
			<link rel="shortcut icon" href="favicon.ico"/>
		</Helmet>
		<Components.CdzvMenu>
			<Override slot="button1" display="none" />
		</Components.CdzvMenu>
		<Components.CdzvHero />
		<Components.CdzvEchanges
			sm-display="flex"
			sm-overflow-y="visible"
			sm-text-align="center"
			margin="30px 0px 0px 0px"
			padding="0px 0 30px 0"
		>
			<Override
				slot="box"
				sm-flex="0 1 auto"
				sm-flex-direction="column"
				sm-align-items="center"
				sm-justify-content="center"
				sm-align-content="center"
				md-display="flex"
				md-align-items="center"
				md-justify-content="center"
				md-flex-wrap="wrap"
				md-align-content="stretch"
				md-flex-direction="column"
				sm-width="90%"
				sm-height="auto"
			/>
			<Override slot="image2" md-padding="0px 0px 20px 0px" sm-height="100px" padding="0px 0px 3px 0px" />
			<Override
				slot="image3"
				md-padding="0px 0px 10px 0px"
				sm-height="80px"
				src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/full-kucoin-logo-600x139.webp?v=2024-06-04T17:09:28.076Z"
				margin="4px 0px 0px 0px"
			/>
			<Override slot="box4" md-padding="0px 0px 20px 0px" sm-width="100%" width="13%" />
			<Override slot="box5" md-padding="0px 0px 20px 0px" sm-width="100%" />
			<Override slot="box6" md-padding="0px 0px 20px 0px" sm-width="100%" />
			<Override slot="box7" md-width="20%" sm-width="30%" />
			<Override slot="image4" sm-height="45px" padding="3px 0px 0px 0px" />
			<Override slot="image1" sm-height="70px" />
			<Override slot="box1" sm-width="100%" />
			<Override slot="image" sm-height="70px" />
			<Override slot="image5" sm-height="90px" padding="0px 0px 5px 0px" />
			<Override slot="image6" />
			<Override slot="box2" sm-width="100%" />
			<Override slot="box3" sm-width="100%" width="11%" />
		</Components.CdzvEchanges>
		<Components.CdzvProfit padding="50px 0 30px 0">
			<Override slot="SectionContent" flex="0 1 auto" />
		</Components.CdzvProfit>
		<Components.CdzvStats padding="10px 0 10px 0" />
		<Components.CdzvFeatures background="--color-white">
			<Override slot="text4">
				Analyze the results of trades on different time frames and change settings to improve statistics. You can use a combination of several indicators to find rare entry points.
			</Override>
			<Override slot="text7">
				After successful testing, automate the sending of trading signals to the exchange or messenger.
			</Override>
		</Components.CdzvFeatures>
		<Components.CdzvCopyTrades height="650px" mix-blend-mode="luminosity">
			<Override
				slot="text1"
				color="#e9ffe9"
				text-align="center"
				text-shadow="4px 4px 0 --color-grey"
				padding="50px 0px 0px 0px"
			>
				Ready-made profitable strategies are available for immediate use as soon as you subscribe
			</Override>
			<Override slot="box" justify-content="center" />
		</Components.CdzvCopyTrades>
		<Components.CdzvBenefits background="--color-light" padding="30px 0 10px 0">
			<Override slot="box1" background="--color-light" />
			<Override slot="image" background="--color-light" border-radius="50px" />
		</Components.CdzvBenefits>
		<Components.CdzvPricing />
		<Components.CdzvComparison>
			<Override
				slot="text10"
				lg-font="normal 500 20px/1.65 --fontFamily-sans"
				md-font="normal 500 15px/1.4 --fontFamily-sans"
				sm-font="15px/0.9 --fontFamily-serifGeorgia"
				sm-margin="0px 0px 40px 0px"
			/>
			<Override slot="text11" lg-font="normal 500 20px/1.65 --fontFamily-sans" md-font="normal 500 15px/1.4 --fontFamily-sans" sm-font="15px/0.9 --fontFamily-serifGeorgia" />
			<Override
				slot="text2"
				lg-font="normal 500 20px/1.65 --fontFamily-sans"
				md-font="normal 500 15px/1.4 --fontFamily-sans"
				sm-font="15px/0.9 --fontFamily-serifGeorgia"
				sm-text-align="center"
				sm-margin="25px 0px 30px 0px"
			/>
			<Override
				slot="text3"
				lg-font="normal 500 20px/1.65 --fontFamily-sans"
				md-font="normal 500 15px/1.4 --fontFamily-sans"
				sm-font="15px/0.9 --fontFamily-serifGeorgia"
				sm-text-align="center"
			/>
			<Override slot="text4" lg-font="normal 500 20px/1.65 --fontFamily-sans" md-font="normal 500 15px/1.4 --fontFamily-sans" sm-font="15px/0.9 --fontFamily-serifGeorgia" />
			<Override
				slot="text5"
				lg-font="normal 500 20px/1.65 --fontFamily-sans"
				md-font="normal 500 15px/1.4 --fontFamily-sans"
				sm-font="15px/0.9 --fontFamily-serifGeorgia"
				sm-margin="0px 0px 40px 0px"
			/>
			<Override
				slot="text6"
				lg-font="normal 500 20px/1.65 --fontFamily-sans"
				md-font="normal 500 15px/1.4 --fontFamily-sans"
				sm-font="15px/0.9 --fontFamily-serifGeorgia"
				sm-margin="0px 0px 40px 0px"
			/>
			<Override
				slot="text7"
				lg-font="normal 500 20px/1.65 --fontFamily-sans"
				md-font="normal 500 15px/1.4 --fontFamily-sans"
				sm-font="15px/0.9 --fontFamily-serifGeorgia"
				sm-margin="0px 0px 40px 0px"
			/>
			<Override
				slot="text8"
				lg-font="normal 500 20px/1.65 --fontFamily-sans"
				md-font="normal 500 15px/1.4 --fontFamily-sans"
				sm-font="15px/0.9 --fontFamily-serifGeorgia"
				sm-text-align="center"
			/>
			<Override slot="text9" lg-font="normal 500 20px/1.65 --fontFamily-sans" md-font="normal 500 15px/1.4 --fontFamily-sans" sm-font="15px/0.9 --fontFamily-serifGeorgia" />
			<Override slot="box3" sm-align-self="flex-start" />
			<Override slot="box13" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px" />
			<Override slot="box14" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px" />
			<Override slot="box25" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px" />
			<Override slot="box26" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px" />
		</Components.CdzvComparison>
		<Components.CdzvUsers background="--color-light" quarkly-title="Reviews" />
		<Components.QuarklycommunityKitYouTube url="https://youtu.be/T1JcchXsQLE" modestbranding quarkly-title="Youtube1" display="none" />
		<Components.CdzvScam />
		<Components.CdzvFaq id="faq" background="--color-white">
			<Override slot="text3">
				It's easy. After studying our video lessons, you will be able to instantly create and test your own strategies. The principle of strategy creation is made as simple as possible for the average TradingView user.
			</Override>
			<Override slot="text7">
				Yes, you can set up to send trade alerts from your or our strategies to your exchange using the Webhooks settings. Or connect to the exchange via our Webhook server.
			</Override>
			<Override slot="text5">
				You can use your strategies on any asset that is available on the TradingView platform and on any timeframe.
			</Override>
			<Override slot="text9">
				Yes, most indicators can be used with CDZV at once. Some require 2-3 lines of code to be added to them, but don't worry - we will make these changes for you for free.
			</Override>
		</Components.CdzvFaq>
		<Components.CdzvFooter margin="0px 0px 0px 0px" background="--color-lightD1" padding="20px 0 40px 0">
			<Override slot="text2" display="none" />
		</Components.CdzvFooter>
		<Components.QuarklycommunityKitCookieUsed margin="0px 0px 0px 0px" quarkly-title="CookieUsed" />
		<Components.QuarklycommunityKitBackToTop padding="0px 0px 0px 0px" margin="0px 0px 0px 0px" quarkly-title="BackToTop" />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"665dd05e1d712a0023346c52"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\nhtml {\nscroll-behavior: smooth;\n}\n"}
			</style>
		</RawHtml>
	</Theme>;
});