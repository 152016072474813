import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Button, Section } from "@quarkly/widgets";
import QuarklycommunityKitLoopText from "./QuarklycommunityKitLoopText";
const defaultProps = {
	"padding": "140px 0 40px 0",
	"quarkly-title": "CDZVHero",
	"background": "--color-white",
	"id": "home"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"justify-content": "space-around",
			"lg-flex-direction": "column",
			"lg-overflow-y": "hidden",
			"lg-overflow-x": "hidden",
			"flex": "0 1 auto"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"lg-width": "100%",
			"lg-padding": "0 50px 50px 50px",
			"padding": "0px 80px 0px 80px",
			"sm-padding": "0 15px 50px 15px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "none",
			"margin": "10px 0px 0px 0px",
			"border-radius": "25px",
			"border-color": "--color-lightD2",
			"sm-flex-direction": "column",
			"align-items": "center",
			"sm-align-items": "flex-start",
			"justify-content": "flex-start"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15:58:42.784Z",
			"display": "block",
			"width": "200px",
			"align-self": "auto",
			"margin": "0px 0px 10px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 800 50px/1.2 --fontFamily-sans",
			"sm-font": "normal 700 42px/1.2 --fontFamily-sans",
			"color": "--darkL2",
			"display": "none",
			"children": <>
				Code Zero
				<br />
				Strategy Builder
			</>
		}
	},
	"quarklycommunityKitLoopText": {
		"kind": "QuarklycommunityKitLoopText",
		"props": {
			"slides": "Automator,Tester,Builder",
			"font": "normal 800 50px/1.2 --fontFamily-sans",
			"padding": "10px 0px 0px 0px"
		}
	},
	"quarklycommunityKitLoopTextOverride": {
		"kind": "Override",
		"props": {
			"slot": "After Text",
			"margin": "0 0px 0 0px",
			"children": <>
				{" "}
			</>
		}
	},
	"quarklycommunityKitLoopTextOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Before Text",
			"margin": "0 0px 0 0px",
			"children": "Code Zero Strategy"
		}
	},
	"quarklycommunityKitLoopTextOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Looped Text",
			"margin": "1px 0px 16px 0px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "10px 0px 50px 0px",
			"font": "normal 300 28px/1.2 --fontFamily-sans",
			"color": "rgba(25, 30, 34, 0.73)",
			"lg-margin": "0px 0px 30px 0px",
			"text-align": "justify",
			"children": "Create, test and automate trading strategies in minutes. Copy trades from our ready-made strategies"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"sm-flex-direction": "column",
			"sm-align-items": "stretch"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"margin": "0px 15px 0px 0px",
			"padding": "12px 28px 12px 28px",
			"background": "#631876",
			"border-radius": "8px",
			"font": "normal 400 17px/1.5 --fontFamily-sans",
			"sm-margin": "0px 0 15px 0px",
			"transition": "background-color 0.2s ease 0s",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "rgba(63, 36, 216, 0)",
			"hover-color": "--darkL1",
			"hover-background": "--color-indigo",
			"type": "link",
			"href": "#pricing",
			"disabled": false,
			"text-decoration-line": "initial",
			"text-align": "center"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18:15:49.125Z",
			"display": "block",
			"width": "100px",
			"sm-align-self": "center"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"align-self": "center",
			"padding": "0px 0px 0px 10px",
			"text-align": "center",
			"font": "--base",
			"sm-align-self": "center",
			"sm-order": "0",
			"sm-flex": "0 1 auto",
			"sm-text-align": "center",
			"children": "3637 users"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14:18:33.517Z",
			"display": "block",
			"width": "50%",
			"object-fit": "cover",
			"lg-width": "100%",
			"object-position": "0 0",
			"padding": "15px 0px 15px 15px",
			"border-radius": "25px 0 0 25px",
			"background": "#ffffff",
			"sm-margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 0px 0",
			"lg-text-align": "left",
			"lg-padding": "15px 0px 15px 0px",
			"sm-padding": "15px 0 15px 0"
		}
	}
};

const CdzvHero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" display="flex" width="1280px" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Image {...override("image")} />
				</Box>
				<Text {...override("text")} />
				<QuarklycommunityKitLoopText {...override("quarklycommunityKitLoopText")}>
					<Override {...override("quarklycommunityKitLoopTextOverride")} />
					<Override {...override("quarklycommunityKitLoopTextOverride1")} />
					<Override {...override("quarklycommunityKitLoopTextOverride2")} />
				</QuarklycommunityKitLoopText>
				<Text {...override("text1")} />
				<Box {...override("box3")}>
					<Button {...override("button")}>
						Start earning money
					</Button>
					<Image {...override("image1")} />
					<Text {...override("text2")} />
				</Box>
			</Box>
			<Image {...override("image2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvHero, { ...Section,
	defaultProps,
	overrides
});
export default CdzvHero;