import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0px 60px 0px",
	"background": "--color-white"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "flex-start",
			"lg-margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 40px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "24px 0px 16px 0px",
			"lg-flex-direction": "row",
			"lg-flex-wrap": "wrap"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--dark",
			"font": "--headline2",
			"lg-text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"lg-width": "100%",
			"children": "Experience of the past for profits in the future"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"color": "--greyD3",
			"font": "--lead",
			"lg-text-align": "center",
			"lg-width": "100%",
			"children": <>
				Analyze trades and find{" "}
				<Strong>
					profitable results
				</Strong>
				{" "}with{" "}
				<Strong>
					professional trading strategy
				</Strong>
				{" "}testing tool based on historical data
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"margin": "0px 0px 32px 0px",
			"lg-width": "50%",
			"lg-margin": "0px 0px 0px 0px",
			"lg-padding": "0px 16px 0px 0px",
			"md-width": "100%",
			"md-margin": "0px 0px 32px 0px",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"padding": "7px 24px 8px 24px",
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"background": "--color-orange",
			"border-radius": "50px",
			"align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"color": "--light",
			"width": "49px",
			"height": "49px",
			"children": "1"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 22px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"children": "Test strategies on real historical data"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"children": "Analyze the results of deals on different time frames and change settings to improve statistics. You can use a combination of several indicators to find rare entry points."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "flex-start",
			"lg-width": "50%",
			"lg-padding": "0px 0px 0px 16px",
			"md-width": "100%",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"padding": "7px 24px 8px 24px",
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"background": "--color-orange",
			"border-radius": "50px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center",
			"color": "--light",
			"width": "49px",
			"height": "49px",
			"children": "2"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 0px 22px"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--darkL2",
			"font": "--headline3",
			"lg-text-align": "left",
			"children": "Automate trading strategies"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--greyD3",
			"font": "--base",
			"lg-text-align": "left",
			"children": "After successful testing, automate the sending of trading signals to the stock exchange or messenger."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"lg-width": "100%",
			"padding": "0px 0px 0px 0px",
			"lg-justify-content": "center",
			"lg-padding": "0px 0px 0px 0px",
			"flex-direction": "row",
			"flex": "0 1 auto",
			"text-align": "center",
			"position": "relative"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/ALGOUSDT%20:%20[CDZV_0017]%20ALGO%20pub%202024-05-17%2019-45-55.png?v=2024-06-03T15:02:43.194Z",
			"max-width": "100%",
			"transform": "translateY(10px)",
			"transition": "transform 0.5s ease-in-out 0s",
			"hover-transform": "translateY(0px)",
			"align-self": "stretch",
			"display": "inline-block"
		}
	}
};

const CdzvFeatures = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Box {...override("box1")}>
				<Text {...override("text2")} />
				<Box {...override("box2")}>
					<Text {...override("text3")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text5")} />
				<Box {...override("box4")}>
					<Text {...override("text6")} />
					<Text {...override("text7")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box5")}>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvFeatures, { ...Section,
	defaultProps,
	overrides
});
export default CdzvFeatures;