import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "50px 0 50px 0",
	"background": "--color-white",
	"margin": "0 0 0 10",
	"sm-margin": "0 0 0 0",
	"quarkly-title": "Profits"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"padding": "15px 15px 15px 15px",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"align-items": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"font": "normal 900 60px/1.2 --fontFamily-sans",
			"color": "--green",
			"text-align": "center",
			"children": "Effectiveness of our strategies in 2024"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--dark",
			"width": "80%",
			"text-align": "center",
			"lg-width": "100%",
			"children": "Financial result of ready-made strategies for the year"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"margin": "30px 0px 30px 0px",
			"border-radius": "25px",
			"background": "rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1723095469034-c3cf31e32730?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 100% 0%/cover no-repeat scroll content-box",
			"min-height": "336px",
			"sm-min-height": "200px",
			"mix-blend-mode": "normal",
			"opacity": "1",
			"align-self": "auto",
			"color": "rgba(0, 0, 0, 0)",
			"text-align": "center",
			"order": "0",
			"flex": "0 1 auto",
			"border-color": "#ffffff"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"padding": "15px 15px 15px 15px",
			"display": "grid",
			"align-items": "center",
			"justify-content": "center",
			"flex-direction": "column",
			"lg-width": "50%",
			"sm-width": "100%",
			"sm-align-self": "center",
			"sm-display": "grid",
			"md-flex": "0 0 auto",
			"md-align-self": "center",
			"md-display": "grid",
			"md-align-items": "center",
			"md-justify-items": "center",
			"md-align-content": "center",
			"lg-align-self": "center",
			"lg-flex": "0 0 auto",
			"lg-align-items": "center",
			"lg-display": "grid",
			"lg-justify-items": "center",
			"lg-align-content": "center",
			"flex": "0 0 auto",
			"align-self": "center",
			"align-content": "center",
			"justify-items": "center"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0",
			"font": "--headline1",
			"color": "--darkL2",
			"md-margin": "0px 0px 16px 0",
			"lg-margin": "0px 0px 16px 0",
			"children": "25+"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--lead",
			"color": "--dark",
			"text-align": "center",
			"children": "ready-made strategies (crypto, stocks, ETFs, futures)."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "15px 15px 15px 15px",
			"display": "grid",
			"align-items": "center",
			"justify-content": "center",
			"flex-direction": "column",
			"lg-width": "50%",
			"sm-width": "100%",
			"sm-align-self": "center",
			"sm-display": "grid",
			"sm-justify-items": "stretch",
			"sm-align-items": "center",
			"md-display": "grid",
			"md-align-items": "center",
			"md-align-self": "center",
			"md-justify-items": "center",
			"lg-flex": "0 0 auto",
			"lg-align-self": "center",
			"lg-display": "flex",
			"lg-align-items": "center",
			"lg-align-content": "center",
			"lg-text-align": "center",
			"flex": "0 0 auto",
			"align-self": "center",
			"justify-items": "center",
			"align-content": "center",
			"text-align": "center",
			"width": "50%"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0",
			"font": "--headline1",
			"color": "--green",
			"lg-text-align": "left",
			"lg-margin": "0px 0px 16px 0",
			"md-margin": "0px 0px 16px 0",
			"sm-text-align": "center",
			"children": "+430%"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--lead",
			"color": "--dark",
			"text-align": "center",
			"children": "Yield of all strategies without leverage"
		}
	}
};

const CdzvProfit = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" max-width="1220px" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")} />
		<Box {...override("box2")}>
			<Text {...override("text2")} />
			<Text {...override("text3")} />
		</Box>
		<Box {...override("box3")}>
			<Text {...override("text4")} />
			<Text {...override("text5")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvProfit, { ...Section,
	defaultProps,
	overrides
});
export default CdzvProfit;